import React from 'react';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { APIURI, MESSAGE } from '../../constants';
import Textbox from '../controls/Textbox';
import Password from '../controls/Password';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = { redirectToRegister: false };
        this.usernameRef = React.createRef();
        this.passwordRef = React.createRef();
    }

    handleSubmit = () => {
        fetch(APIURI + '/auth/login', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: this.usernameRef.current.state.value,
                password: this.passwordRef.current.state.value
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (!result.success) {
                    toast.error(result.message);
                    return;
                }
                if (result.data.access_granted) {
                    localStorage.setItem("auth-token", result.data.token);
                    this.setState({ redirectToRegister: true });
                } else {
                    toast.error("You are no authorized to access this application");
                }
            },
            (error) => {
                toast.error(MESSAGE);
            }
        )
    }

    render() {
        const usernameControlId = "login-form-username";
        const passwordControlId = "login-form-password";
        if (this.state.redirectToRegister)
        {
            return (
                <Navigate to="/home" />
            );
        }
        return (
            <div className="container">
                <ToastContainer theme="colored" />
                <div className={"card"} style={{ maxWidth: "340px", margin: "100px auto" }}>
                    <div style={{ padding: "20px" }}>
                        <h3 style={{ marginBottom: "20px", textAlign: "center" }}>P. M. Login</h3>
                        <div className="form-group">
                            <label htmlFor={usernameControlId}>User Name</label>
                            <Textbox ref={this.usernameRef} id={usernameControlId}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor={passwordControlId}>Password</label>
                            <Password ref={this.passwordRef} id={passwordControlId}/>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <button className="btn btn-dark" onClick={this.handleSubmit}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;