import React from 'react';

class Home extends React.Component {

    render() {
        return (
            <ul className={"list-group"}>
                <li className={"list-group-item"}><a href="/stock-exchange/">Stock Exchange</a></li>
            </ul>
        );
    }
}

export default Home;