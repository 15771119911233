import React from 'react';

class Page404 extends React.Component {

    render() {
        return (
            <h1 style={{ marginTop: "20%", textAlign: "center" }}>404, page not found.</h1>
        );
    }
}

export default Page404;