import React from "react";

class Password extends React.Component {

	constructor(props) {
		super(props);
		this.state = { id: props.id, value: props.value ?? "" };
	}

	handleChange = (event) => {
		this.setState({ value: event.target.value });
	}

	render() {
		return (
		<input id={this.state.id} type={"password"} className={"form-control"} value={this.state.value} onChange={this.handleChange} />
		);
	}
}

export default Password;